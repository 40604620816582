// components/NationSelect.tsx
import React from 'react';
import { useDispatch } from 'react-redux';
import { Nation } from '../../../context/interfaces';
import data from '../../../constants/nations.json';
import {
  setSelectedNation1,
  resetStates
} from '../../../store/unitsSlice';

const Nation1Select = () => {
  const dispatch = useDispatch();

  const handleSelectNation = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedNation = data.nations.find((nation: Nation) => nation.name === e.target.value) || null;
    dispatch(setSelectedNation1(selectedNation));
    dispatch(resetStates('unit1'));
  };

  return (
    <select onChange={handleSelectNation}>
      <option value=''>Select Nation</option>
      {data.nations.map((nation: Nation) => (
        <option key={nation.id} value={nation.name}>{nation.name}</option>
      ))}
    </select>
  );
};

export default Nation1Select;
