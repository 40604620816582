import { Grid, Typography } from "@mui/material";
import RoundRobin from "../standing/roundrobin";
import SchedulePerTournament from "../schedule/schedulepertournament";

interface TournamentGroupProps {
    name: string,
    tournamentId: string
}

const TournamentGroup = ({ name, tournamentId } : TournamentGroupProps) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <RoundRobin tournamentId={tournamentId}></RoundRobin>
            </Grid>
            <Grid item xs={12}>
                <SchedulePerTournament tournamentId={`tournaments/${tournamentId}-A`}></SchedulePerTournament>
            </Grid>
        </Grid>
    );
};

export default TournamentGroup;
