import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UnitPreview from '../UnitPreview';
import { RootState, AppDispatch } from '../../../store'; // Adjust the path as necessary
import { recalculateUnitPreview1 } from '../../../store/unitsSlice';

const Unit1Preview = () => {
  const dispatch: AppDispatch = useDispatch();
  const unitPreview1 = useSelector((state: RootState) => state.units.unitPreview1);
  const unitPreview1Status = useSelector((state: RootState) => state.units.status);
  const { academyUpgrades1, blacksmithUpgrades1, unitAttackUpgrades1, unitDefenceUpgrades1 } = useSelector((state: RootState) => state.units);

  useEffect(() => {
    if (academyUpgrades1.length > 0 || blacksmithUpgrades1.length > 0 || unitAttackUpgrades1.length > 0 || unitDefenceUpgrades1.length > 0) {
      dispatch(recalculateUnitPreview1());
    }

  }, [academyUpgrades1, blacksmithUpgrades1, unitAttackUpgrades1, unitDefenceUpgrades1]);

  if (unitPreview1Status === 'loading') {
    return <p>Loading...</p>;
  }

  if (unitPreview1Status === 'failed') {
    return <p>Error loading unit preview</p>;
  }

  return (
    <div>
      {unitPreview1 ? (
        <UnitPreview upgradetUnit={unitPreview1} />
      ) : (
        <p>No unit preview available</p>
      )}
    </div>
  );
};

export default Unit1Preview;
