// components/Unit1Upgrades.tsx
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Upgrades } from '../../../context/interfaces';
import Upgrade2Attack from './Upgrade2Attack';
import Upgrade2Defence from './Upgrade2Defence';

const Unit2Upgrades = () => {
  const selectedUnitNation2 = useSelector((state: RootState) => state.units.selectedUnitNation2);
  const [upgrades, setUpgrades] = useState<Upgrades | undefined>();

  useEffect(() => {
    setUpgrades({
      attack: selectedUnitNation2?.upgrades?.attack,
      defence: selectedUnitNation2?.upgrades?.defence
    });
  }, [selectedUnitNation2]);

  return (
    upgrades ? (
      <div className='unit-upgrade-wrap'>
        <Upgrade2Attack options={upgrades.attack} />
        <Upgrade2Defence options={upgrades.defence} />
      </div>
    ) : null
  );
};

export default Unit2Upgrades;
