import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AcademyUpgradeItem from '../AcademyUpgradeItem';
import { AcademyUpgrade } from '../../../context/interfaces';
import { RootState } from '../../../store'; // Adjust the path as necessary

type Props = {
  name: string;
};

const Academy1Upgrades = ({ name }: Props) => {
  // Use useSelector to access Redux state
  const selectedNation1 = useSelector((state: RootState) => state.units.selectedNation1);
  const selectedUnitNation1 = useSelector((state: RootState) => state.units.selectedUnitNation1);
  const [academyUpgrades, setAcademyUpgrades] = useState<AcademyUpgrade[]>([]);

  useEffect(() => {
    if (selectedUnitNation1 && selectedNation1) {
      const upgrades = selectedNation1.academyUpgrades.filter((upgrade) => 
        upgrade.affectedUnits?.includes(selectedUnitNation1.id)
      );
      setAcademyUpgrades(upgrades || []);
    } else {
      setAcademyUpgrades([]);
    }
  }, [selectedUnitNation1, selectedNation1]);

  return (
    <div className='academy-wrapper'>
      {academyUpgrades.length > 0 ? (
        academyUpgrades.map((upgrade) => (
          <div key={upgrade.id}>
            <AcademyUpgradeItem name={name} index={upgrade.id} upgrade={upgrade} selectedUnitNation={selectedUnitNation1} />
          </div>
        ))
      ) : (
        <p>No upgrades available</p>
      )}
    </div>
  );
};

export default Academy1Upgrades;
