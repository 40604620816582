import { Grid } from "@mui/material";
import SchedulePerTournament from "../schedule/schedulepertournament";

interface TournamentPlayoffProps {
    name: string,
    tournamentId: string
}

const TournamentPlayoff = ({ name, tournamentId } : TournamentPlayoffProps) => {

    function getBracket() {
        const rawHTML = '<iframe src="https://challonge.com/23w7osl4/module" width="100%" height="730" frameborder="0" scrolling="auto" allowtransparency="true"></iframe>';
    
        return (
            <div dangerouslySetInnerHTML={{ __html: rawHTML }} />
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {getBracket()}
            </Grid>
            <Grid item xs={12}>
                <SchedulePerTournament tournamentId={`tournaments/${tournamentId}-A`}></SchedulePerTournament>
            </Grid>
        </Grid>
    );
};

export default TournamentPlayoff;
