import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import bla6 from '../../assets/bla.6.jpg';
import bla3 from '../../assets/bla.3.jpg';
import bla4 from '../../assets/bla.4.jpg';
import bla5 from '../../assets/bla.5.jpg';
import { BlacksmithUpgrade, Unit } from '../../context/interfaces';
import { addBlacksmithUpgrade1, addBlacksmithUpgrade2 } from '../../store/unitsSlice'; // Ensure this action is correctly defined in your slice

type Props = {
  index: string;
  upgrade: BlacksmithUpgrade;
  selectedUnitNation: Unit | null;
  name: string;
};

const BlacksmithUpgradeItem = ({ upgrade, index, name, selectedUnitNation }: Props) => {
  const dispatch = useDispatch();
  const [isClickable, setIsClickable] = useState(true);
  const [animateProgressBar, setAnimateProgressBar] = useState(false);

  const getBla = (str: string) => {
    const data = str.split('.')[1];
    return data === '3' ? bla3 : data === '4' ? bla4 : data === '5' ? bla5 : bla6;
  };

  useEffect(() => {
    setIsClickable(selectedUnitNation !== null);
  }, [selectedUnitNation, name]);

  // Debounce utility function
  const debounce = (func: (...args: any[]) => void, wait: number) => {
    let timeout: NodeJS.Timeout;

    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(null, args);
      }, wait);
    };
  };

  // Handle the upgrade logic, debounced
  const handleUpgrade = useCallback(
    debounce(() => {
      if (name === 'blacksmith1') {
        dispatch(addBlacksmithUpgrade1(upgrade));
        setIsClickable(false);
      } else if (name === 'blacksmith2') {
        dispatch(addBlacksmithUpgrade2(upgrade));
        setIsClickable(false);
      }
    }, 1000), // 1000ms debounce delay
    [dispatch, upgrade, name]
  );

  // Immediate animation and debounced upgrade
  const handleSelect = () => {
    if (isClickable) {
      setAnimateProgressBar(true);

      setTimeout(() => {
        setAnimateProgressBar(false);
      }, 1000); // Match this duration to your CSS animation duration

      handleUpgrade(); // Call the debounced upgrade function
    }
  };

  return (
    <div key={index} className='img-upgrade-wrapper'>
      <img
        className={`img-upgrade ${!isClickable ? 'disabled' : ''}`}
        onClick={handleSelect}
        src={getBla(upgrade.id)}
        alt={upgrade.id}
      />
      <div className="progress-bar-container">
        <div className={`progress-bar ${animateProgressBar ? 'animate' : ''}`}></div>
      </div>
    </div>
  );
};

export default BlacksmithUpgradeItem;
