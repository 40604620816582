import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import attack2 from '../../../assets/attack2.jpg';
import attack3 from '../../../assets/attack3.jpg';
import attack4 from '../../../assets/attack4.jpg';
import attack5 from '../../../assets/attack5.jpg';
import attack6 from '../../../assets/attack6.jpg';
import attack7 from '../../../assets/attack7.jpg';
import { Upgrade } from '../../../context/interfaces';
import { addUnitAttackUpgrade1 } from '../../../store/unitsSlice';

// Debounce utility function
const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout;

  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(null, args);
    }, wait);
  };
};

type Props = {
  options: Upgrade[] | undefined;
};

const Upgrade1Attack = ({ options = [] }: Props) => {
  const dispatch = useDispatch();
  const selectedUnitNation1 = useSelector((state: RootState) => state.units.selectedUnitNation1);
  const [currentAttackIndex, setCurrentAttackIndex] = useState(1);
  const [isAttackClickable, setIsAttackClickable] = useState(true);
  const [animateProgressBar, setAnimateProgressBar] = useState(false);

  useEffect(() => {
    setCurrentAttackIndex(1);
    setIsAttackClickable(true);
  }, [selectedUnitNation1]);

  // Handle the upgrade logic, debounced
  const handleUpgrade = useCallback(

    debounce(() => {
      if (isAttackClickable && options.length > 0) {
        const maxIndex = selectedUnitNation1?.id === 'dragoon' ? Math.min(4, options.length) : options.length;

        if (currentAttackIndex <= maxIndex) {
          dispatch(addUnitAttackUpgrade1(options[currentAttackIndex - 1]));

          // Reset index if it exceeds available options
          if (currentAttackIndex >= maxIndex) {
            setCurrentAttackIndex(1);
            setIsAttackClickable(false);
          } else {
            setCurrentAttackIndex(currentAttackIndex + 1);
          }
        }
      }
    }, 1000), // 1000ms debounce delay
    [currentAttackIndex, isAttackClickable, options, dispatch, selectedUnitNation1]
  );

  // Immediate animation and debounced upgrade
  const handleSelect = () => {
    if (isAttackClickable) {
      setAnimateProgressBar(true);

      setTimeout(() => {
        setAnimateProgressBar(false);
      }, 1000); // Match this duration to your CSS animation duration

      handleUpgrade(); // Call the debounced upgrade function
    }
  };

  const getAttack = (index: number) => {
    // Determine image based on unit type and index
    if (selectedUnitNation1?.id === 'dragoon' && index > 4) {
      setCurrentAttackIndex(1);
      setIsAttackClickable(false);
    }

    switch (index) {
      case 2:
        return attack2;
      case 3:
        return attack3;
      case 4:
        return attack4;
      case 5:
        return attack5;
      case 6:
        return attack6;
      case 7:
        return attack7;
      default:
        return '';
    }
  };

  const getImage = () => {
    return getAttack(currentAttackIndex + 1);
  };

  // Limit options to show only the first 3 upgrades if the unit is "dragun"
  const limitedOptions = selectedUnitNation1?.id === 'dragun' ? options.slice(0, 4) : options;

  return (
    <div className='img-upgrade-wrapper'>
      {limitedOptions.length === 0 ? null : (
        <>
          <img
            className={`img-upgrade ${!isAttackClickable ? 'disabled' : ''}`}
            onClick={handleSelect}
            src={getImage()}
            alt="upgrade"
            loading="lazy"
          />
          <div className="progress-bar-container">
            <div className={`progress-bar ${animateProgressBar ? 'animate' : ''}`}></div>
          </div>
        </>
      )}
    </div>
  );
};

export default Upgrade1Attack;
