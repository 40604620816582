import pike from '../../assets/attack_pike.png';
import muskeet from '../../assets/attack_muskeet.png';
import sword from '../../assets/attack_sword.png';
import arrow from '../../assets/attack_arrow.png';
import firearrow from '../../assets/attack_firearrow.png';
import mortarball from '../../assets/attack_mortarball.png';
import heal from '../../assets/attack_heal.png';
const UnitWeapon = ({ weapons }: any) => {
    return (
        <div>
            {weapons && Object.entries(weapons).map(([weaponName, weaponProperties]) => (
                <div className='weapon' key={weaponName}>
                    <h3>{
                        weaponName === "pike" ? <img alt="pike" src={pike} /> :
                            weaponName === "bullet" ? <img alt="muskeet" src={muskeet} /> :
                                weaponName === "sword" ? <img alt="sword" src={sword} /> :
                                    weaponName === "arrow" ? <img alt="arrow" src={arrow} /> :
                                        weaponName === "firearrow" ? <img alt="firearrow" src={firearrow} /> :
                                            weaponName === "mortarball" ? <img alt="mortarball" src={mortarball} /> :
                                                weaponName === "heal" ? <img alt="heal" src={heal} /> : weaponName


                    }</h3>
                    <div>
                        {Object.entries(weaponProperties as { [s: string]: unknown }).map(([propertyKey, propertyValue]) => (
                            <div key={propertyKey}>

                                {/* If weaponName is "heal", display "heal" with the property value */}
                                {weaponName === "heal" && propertyKey === "damage" ? (
                                    <strong>heal:</strong>
                                ) : (
                                    <strong>{propertyKey}:</strong>
                                )}
                                {(propertyValue as number).toFixed(1)}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}
export default UnitWeapon;