// components/Unit1Select.tsx
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UnitSelect from '../UnitSelect';
import { RootState } from '../../../store';
import { setUnitPreview2, setSelectedUnitNation2 } from '../../../store/unitsSlice';
import { Unit } from '../../../context/interfaces';

const Unit2Select = () => {
  const dispatch = useDispatch();

  const selectedNation2 = useSelector((state: RootState) => state.units.selectedNation2);
  const selectedUnitNation2 = useSelector((state: RootState) => state.units.selectedUnitNation2);



  const handlesetSelectedUnitNation2 = (unit: Unit | null) => {
    dispatch(setSelectedUnitNation2(unit));
  };

  return (
    <UnitSelect
      name="unit2"
      selectedNation={selectedNation2}
      setSelectedUnitNation={handlesetSelectedUnitNation2}
      selectedUnitNation={selectedUnitNation2}
    />
  );
};

export default Unit2Select;
