import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import aca12 from '../../assets/aca.12.jpg';
import aca13 from '../../assets/aca.13.jpg';
import aca14 from '../../assets/aca.14.jpg';
import aca15 from '../../assets/aca.15.jpg';
import aca31 from '../../assets/aca.31.jpg';
import aca32 from '../../assets/aca.32.jpg';
import aca33 from '../../assets/aca.33.jpg';
import aca34 from '../../assets/aca.34.jpg';
import aca35 from '../../assets/aca.35.jpg';
import aca36 from '../../assets/aca.36.jpg';
import { AcademyUpgrade, Unit } from '../../context/interfaces';
import { addAcademyUpgrade1, addAcademyUpgrade2 } from '../../store/unitsSlice';

// Debounce utility function
const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
};

type Props = {
  upgrade: AcademyUpgrade;
  index: string;
  name: string;
  selectedUnitNation: Unit | null;
};

const AcademyUpgradeItem = ({ upgrade, index, name, selectedUnitNation }: Props) => {
  const dispatch = useDispatch();
  const [animateProgressBar, setAnimateProgressBar] = useState(false);
  const [isClickable, setIsClickable] = useState(true);

  // Function to select the appropriate image based on the upgrade ID
  const getAca = (str: string) => {
    const data = str.substring(str.indexOf('.') + 1);
    switch (data) {
      case '12':
        return aca12;
      case '13':
        return aca13;
      case '14':
        return aca14;
      case '15':
        return aca15;
      case '31':
        return aca31;
      case '32':
        return aca32;
      case '33':
        return aca33;
      case '34':
        return aca34;
      case '35':
        return aca35;
      default:
        return aca36;
    }
  };

  // Update clickability based on the selected unit
  useEffect(() => {
    setIsClickable(selectedUnitNation !== null);
  }, [selectedUnitNation, name]);

  // Function to perform the dispatch based on the academy name
  const performUpgrade = useCallback(() => {
    if (name === 'academy1') {
      dispatch(addAcademyUpgrade1(upgrade));
    } else if (name === 'academy2') {
      dispatch(addAcademyUpgrade2(upgrade));
    }
    setIsClickable(false); // Prevent further clicks after upgrade
  }, [dispatch, name, upgrade]);

  // Create a debounced version of the performUpgrade function
  const debouncedPerformUpgrade = useCallback(
    debounce(() => {
      performUpgrade();
    }, 1000), // 1000ms debounce delay
    [performUpgrade]
  );

  // Handler for click events on the upgrade item
  const handleSelect = () => {
    if (!isClickable) return; // Do nothing if not clickable

    setAnimateProgressBar(true); // Start the animation

    debouncedPerformUpgrade(); // Debounced dispatch

    // Stop the animation after the duration (match this to your CSS animation duration)
    setTimeout(() => {
      setAnimateProgressBar(false);
    }, 1000);
  };

  return (
    <div key={index} className='img-upgrade-wrapper'>
      <img
        className={`img-upgrade ${!isClickable ? 'disabled' : ''}`}
        src={getAca(upgrade.id)}
        alt={upgrade.id}
        loading="lazy"
        onClick={handleSelect}
      />
      <div className="progress-bar-container">
        <div className={`progress-bar ${animateProgressBar ? 'animate' : ''}`}></div>
      </div>
    </div>
  );
};

export default AcademyUpgradeItem;
