import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetStanding } from "../../services/StandingService";
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography } from "@mui/material";

interface RoundRobinProps {
    tournamentId?: string
}

interface Standing {
    player: string;
    games: number;
    wins: number;
    draws: number;
    losses: number;
    wl_wins: number;
    wl_losses: number;
    points: number;
}

const RoundRobin = ({ tournamentId } : RoundRobinProps) => {
    const params = useParams();
    const [standings, setStandings] = useState<Standing[]>([]);

    useEffect(() => {
        let id = params.id;
        if (tournamentId !== undefined) {
            id = tournamentId;
        }
        if (id === null || id === undefined) return;
        GetStanding(id).then((data) => {
            const standingsMap: Map<string, Standing> = new Map();
            data.forEach((match: { result: string; sideA: string; sideB: string; }) => {
                const result = match.result.split('-').map(Number);
                const scoreA = result[0];
                const scoreB = result[1];
                if (!standingsMap.has(match.sideA)) {
                    standingsMap.set(match.sideA, {
                        player: match.sideA,
                        games: 0,
                        wins: 0,
                        draws: 0,
                        losses: 0,
                        wl_wins: 0,
                        wl_losses: 0,
                        points: 0
                    });
                }
                if (!standingsMap.has(match.sideB)) {
                    standingsMap.set(match.sideB, {
                        player: match.sideB,
                        games: 0,
                        wins: 0,
                        draws: 0,
                        losses: 0,
                        wl_wins: 0,
                        wl_losses: 0,
                        points: 0
                    });
                }
                if (scoreA > 0 || scoreB > 0)
                {
                    const teamA = standingsMap.get(match.sideA)!;
                    const teamB = standingsMap.get(match.sideB)!;
                    teamA.games++;
                    teamB.games++;
    
                    teamA.points += scoreA;
                    teamB.points += scoreB;
    
                    if (scoreA > scoreB) {
                        teamA.wins++;
                        teamB.losses++;
                    } else if (scoreA < scoreB) {
                        teamB.wins++;
                        teamA.losses++;
                    } else {
                        teamB.draws++;
                        teamA.draws++;
                    }
                    teamA.wl_wins += scoreA;
                    teamA.wl_losses += scoreB;
                    teamB.wl_wins += scoreB;
                    teamB.wl_losses += scoreA;
                }
            });
            const standings: Standing[] = Array.from(standingsMap.values());
            standings.sort((a, b) => a.losses - b.losses);
            standings.sort((a, b) => b.wins - a.wins);
            standings.sort((a, b) => (b.wl_wins - b.wl_losses) - (a.wl_wins - a.wl_losses));
            standings.sort((a, b) => b.points - a.points);
            setStandings(standings);
        });
    }, []);

    var position = 0;
    var positionState = ``;
    var positionValue = 0;
    function DeterminePosition(record: Standing) {
        let newState = `${record.points}-${record.wl_wins}-${record.wl_losses}-${record.wins}-${record.losses}`;
        position++;
        if (positionState !== newState)
        {
            positionState = newState;
            positionValue = position;
            return positionValue;   
        }
        return positionValue;
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><Typography color="grey">#</Typography></TableCell>
                            <TableCell align="left"><Typography color="grey">name</Typography></TableCell>
                            <TableCell align="right"><Typography color="grey">g</Typography></TableCell>
                            <TableCell align="right"><Typography color="grey">w</Typography></TableCell>
                            <TableCell align="right"><Typography color="grey">d</Typography></TableCell>
                            <TableCell align="right"><Typography color="grey">l</Typography></TableCell>
                            <TableCell align="right"><Typography color="grey">w-l</Typography></TableCell>
                            <TableCell align="right"><Typography color="grey">p</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {standings.map((row) => (
                        <TableRow
                            key={row.player}
                        >
                            <TableCell align="center">{DeterminePosition(row)}</TableCell>
                            <TableCell align="left">{row.player}</TableCell>
                            <TableCell align="right">{row.games}</TableCell>
                            <TableCell align="right">{row.wins}</TableCell>
                            <TableCell align="right">{row.draws}</TableCell>
                            <TableCell align="right">{row.losses}</TableCell>
                            <TableCell align="right">{row.wl_wins}-{row.wl_losses}</TableCell>
                            <TableCell align="right">{row.points}</TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
export default RoundRobin;