import UnitImage from "./UnitImage";
import clock from '../../assets/clock.png';
import wood from '../../assets/wood.png';
import food from '../../assets/food.png';
import gold from '../../assets/gold.png';
import stone from '../../assets/stone.png';
import coal from '../../assets/coal.png';
import iron from '../../assets/iron.png';
import shield from '../../assets/shield.png';
import UnitProtection from "./UnitProtection";
import UnitWeapon from "./UnitWeapon";
import { Unit } from "../../context/interfaces";
type Props = {
    upgradetUnit: Unit | null
}
const UnitPreview = ({ upgradetUnit}: Props) => {
    return (
        <div className="wrapper-info">
        <h2 className='text-center'>{upgradetUnit?.name}</h2>
      
        <div className="wrapper-profile">
        <UnitImage id={upgradetUnit?.id} />
            <div className="img-container">{upgradetUnit?.shield} <img src={shield} alt="shield" /></div>
            <div className="img-container">{upgradetUnit?.buildtime?.toFixed(1)} <img className="icon" src={clock} alt="clock" /></div>

        </div>
        <div className="hp-elem">{upgradetUnit?.maxhp} HP </div>
        <div className="wrapper-main"> 

            <div className="wrapper_resource">
                <div className="img-container">{upgradetUnit?.cost?.['wood'] } <img className="icon" src={wood} alt="wood" /></div>
                <div className="img-container">{upgradetUnit?.cost?.['food']} <img className="icon" src={food} alt="food" /></div>
                <div className="img-container">{upgradetUnit?.cost?.['gold']} <img className="icon" src={gold} alt="gold" /></div>
                <div className="img-container">{upgradetUnit?.cost?.['stone']} <img className="icon" src={stone} alt="stone" /></div>
                <div className="img-container">{upgradetUnit?.cost?.['coal']} <img className="icon" src={coal} alt="coal" /></div>
                <div className="img-container">{upgradetUnit?.cost?.['iron']} <img className="icon" src={iron} alt="iron" /></div>
            </div>
        </div>

        <div className="updated-wrapper">
            <div>
                 <UnitProtection protection={upgradetUnit?.protection} /> 
                <div>
                    <h3>Weapon</h3>
                    <UnitWeapon weapons={upgradetUnit?.weapons} />
                </div>
            </div>

        </div>
    </div>
    );
    }
export default UnitPreview;