import PageTitle from "../../components/PageTitle";
import Layout from "../../layout/layout";
import TournamentGroup from "./tournamentgroup";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from "react";
import { Grid } from "@mui/material";
import TournamentPlayoff from "./tournamentplayoff";

const Title = "World Cup 2024";

enum TournamentType{
    Groups,
    Playoffs
}

function createGroup(
    name: string,
    tournamentType: TournamentType,
    tournamentId: string,
) {
    return { name, tournamentType, tournamentId };
};

const groups = [
    createGroup("Group A", TournamentType.Groups, "34"),
    createGroup("Group B", TournamentType.Groups, "35"),
    createGroup("Group C", TournamentType.Groups, "36"),
    createGroup("Group D", TournamentType.Groups, "37"),
    createGroup("Group E", TournamentType.Groups, "38"),
    createGroup("Group F", TournamentType.Groups, "39"),
    createGroup("Group G", TournamentType.Groups, "40"),
    createGroup("Group H", TournamentType.Groups, "41"),
    createGroup("Playoff", TournamentType.Playoffs, "42"),
];

const Tournament = () => {
    const [group, setGroup] = useState('Group A');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setGroup(newValue);
    };

    function renderTabPanelItem(tournamentType : TournamentType, name : string, tournamentId : string) {
        switch(tournamentType) {
            case TournamentType.Groups:
                return (
                    <TabPanel value={name}>
                        <TournamentGroup name={name} tournamentId={tournamentId}></TournamentGroup>
                    </TabPanel>
                );
            case TournamentType.Playoffs:
                return (
                    <TabPanel value={name}>
                        <TournamentPlayoff name={name} tournamentId={tournamentId}></TournamentPlayoff>
                    </TabPanel>
                );
            default:
                return <></>;
        }
    }

    return (
        <Layout>
            <Grid container>
                <Grid item xs={12} textAlign={"center"} mb={5}>
                    <PageTitle text={Title} />
                </Grid>
                <Grid item xs={12}>
                    <TabContext value={group}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                            {groups.map((row) => (
                                <Tab label={`${row.name}`} value={row.name} />
                            ))}
                            </TabList>
                        </Box>
                        {groups.map((row) => (
                            renderTabPanelItem(row.tournamentType, row.name, row.tournamentId)
                        ))}
                    </TabContext>
                </Grid>
            </Grid>
        </Layout>
    );
};
export default Tournament;

