// components/Unit1Select.tsx
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UnitSelect from '../UnitSelect';
import { RootState } from '../../../store';
import {  setSelectedUnitNation1 } from '../../../store/unitsSlice';
import { Unit } from '../../../context/interfaces';

const Unit1Select = () => {
  const dispatch = useDispatch();

  const selectedNation1 = useSelector((state: RootState) => state.units.selectedNation1);
  const selectedUnitNation1 = useSelector((state: RootState) => state.units.selectedUnitNation1);


  const handleSetSelectedUnitNation1 = (unit: Unit | null) => {
    dispatch(setSelectedUnitNation1(unit));
  };

  return (
    <UnitSelect
      name="unit1"
      selectedNation={selectedNation1}
      setSelectedUnitNation={handleSetSelectedUnitNation1}
      selectedUnitNation={selectedUnitNation1}
    />
  );
};

export default Unit1Select;
