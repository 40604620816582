import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BlacksmithUpgradeItem from '../BlacksmithUpgradeItem';
import { BlacksmithUpgrade } from '../../../context/interfaces';
import { RootState } from '../../../store'; // Adjust the path as necessary

type Props = {
  name: string;
};

const Blacksmith2Upgrades = ({ name }: Props) => {
  // Use useSelector to access Redux state
  const selectedNation2 = useSelector((state: RootState) => state.units.selectedNation2);
  const selectedUnitNation2 = useSelector((state: RootState) => state.units.selectedUnitNation2);

  const [blacksmithUpgrades, setBlacksmithUpgrades] = useState<BlacksmithUpgrade[]>([]);

  useEffect(() => {
    if (selectedUnitNation2 && selectedNation2) {
      const upgrades = selectedNation2.blacksmithUpgrades.filter((upgrade) =>
        upgrade.affectedUnits?.includes(selectedUnitNation2.id)
      );
      setBlacksmithUpgrades(upgrades || []);
    } else {
      setBlacksmithUpgrades([]);
    }
  }, [selectedUnitNation2, selectedNation2]);

  return (
    <div className='blacksmith-wrapper'>
      {blacksmithUpgrades.length > 0 ? (
        blacksmithUpgrades.map((upgrade: BlacksmithUpgrade) => (
          <div key={upgrade.id}>
            <BlacksmithUpgradeItem
              index={upgrade.id}
              upgrade={upgrade}
              name={name}
              selectedUnitNation={selectedUnitNation2}
            />
          </div>
        ))
      ) : (
        <p>No upgrades available</p>
      )}
    </div>
  );
};

export default Blacksmith2Upgrades;
