import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import defence2 from '../../../assets/defence2.jpg';
import defence3 from '../../../assets/defence3.jpg';
import defence4 from '../../../assets/defence4.jpg';
import defence5 from '../../../assets/defence5.jpg';
import defence6 from '../../../assets/defence6.jpg';
import defence7 from '../../../assets/defence7.jpg';
import { Upgrade } from '../../../context/interfaces';
import { addUnitDefenceUpgrade1 } from '../../../store/unitsSlice';

type Props = {
  options: Upgrade[] | undefined;
};

const Upgrade1Defence = ({ options = [] }: Props) => {
  const dispatch = useDispatch();
  const selectedUnitNation1 = useSelector((state: RootState) => state.units.selectedUnitNation1);
  const [isDefenceClickable, setIsDefenceClickable] = useState(true);
  const [currentDefenceIndex, setCurrentDefenceIndex] = useState(1);
  const [animateProgressBar, setAnimateProgressBar] = useState(false);

  useEffect(() => {
    setCurrentDefenceIndex(1);
    setIsDefenceClickable(true);
  }, [selectedUnitNation1]);

  // Debounce utility function
  const debounce = (func: (...args: any[]) => void, wait: number) => {
    let timeout: NodeJS.Timeout;

    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(null, args);
      }, wait);
    };
  };

  // Handle the upgrade logic, debounced
  const handleUpgrade = useCallback(
    debounce(() => {
      if (isDefenceClickable) {
        if (currentDefenceIndex < options.length) {
          dispatch(addUnitDefenceUpgrade1(options[currentDefenceIndex - 1]));
          if (currentDefenceIndex + 1 > options.length) {
            setCurrentDefenceIndex(1);
            setIsDefenceClickable(false);
          } else {
            setCurrentDefenceIndex(currentDefenceIndex + 1);
          }
        } else if (currentDefenceIndex === options.length) {
          dispatch(addUnitDefenceUpgrade1(options[currentDefenceIndex - 1]));
          setCurrentDefenceIndex(1);
          setIsDefenceClickable(false);
        }
      }
    }, 1000), // 1000ms debounce delay
    [dispatch, currentDefenceIndex, options, isDefenceClickable]
  );

  // Immediate animation and debounced upgrade
  const handleSelect = () => {
    if (isDefenceClickable) {
      setAnimateProgressBar(true);

      setTimeout(() => {
        setAnimateProgressBar(false);
      }, 1000); // Match this duration to your CSS animation duration

      handleUpgrade(); // Call the debounced upgrade function
    }
  };

  const getDefence = (index: number) => {
    switch (index) {
      case 2:
        return defence2;
      case 3:
        return defence3;
      case 4:
        return defence4;
      case 5:
        return defence5;
      case 6:
        return defence6;
      case 7:
        return defence7;
      default:
        return '';
    }
  };

  const getImage = () => {
    return getDefence(currentDefenceIndex + 1);
  };

  return (
    <div className='img-upgrade-wrapper'>
      {options.length === 0 ? null : (
        <>
          <img
            className={`img-upgrade ${!isDefenceClickable ? 'disabled' : ''}`}
            onClick={handleSelect}
            src={getImage()}
            alt="upgrade"
            loading="lazy"
          />
          <div className="progress-bar-container">
            <div className={`progress-bar ${animateProgressBar ? 'animate' : ''}`}></div>
          </div>
        </>
      )}
    </div>
  );
};

export default Upgrade1Defence;
