/* doc: https://www.npmjs.com/package/react-localization */
import LocalizedStrings from 'react-localization';

let Strings = new LocalizedStrings({
    en:{
        /* header */
        news: "News",
        rules: "Rules",
        ratings: "Ratings",
        tournaments: "Tournaments",
        schedule: "Schedule",
        units: "Units",
        signin: "Sign In",
        manage_games: "Games",
        manage_players: "Players",
        logout: "Logout",
        settings: "Settings",
        settings_nickname: "Nickname",
        settings_save: "Save",
        /* ratings page */
        ratings_title: "RANKING",
        ratings_worldchampion: "World Champion",
        ratings_name: "Name",
        ratings_comment: "Comment",
        ratings_score: "Score",
        /* tournaments page */
        tournaments_title: "Tournaments",
        tournaments_card_participants: "participants",
        tournaments_card_created_at: "Created at",
        tournaments_card_learn_more: "LEARN MORE",
        /* single tournament */
        tournament_rules: "Rules",
        tournament_participants: "Participants",
        tournament_games: "Games",
        tournament_bracket: "Bracket",
        tournament_rules_settings_setting: "Setting",
        tournament_rules_settings_value: "Value",
        tournament_rules_settings_season: "Season",
        tournament_rules_settings_map_shape: "Map shape",
        tournament_rules_settings_terrain_type: "Terrain type",
        tournament_rules_settings_starting_resources: "Starting resources",
        tournament_rules_settings_minerals: "Minerals",
        tournament_rules_settings_map_size: "Map size",
        tournament_rules_settings_start_options: "Start options",
        tournament_rules_settings_baloon_options: "Baloon options",
        tournament_rules_settings_cannons: "Cannons",
        tournament_rules_settings_peace_time: "Peace time",
        tournament_rules_settings_18_century_options: "Eighteenth century options",
        tournament_rules_settings_capture: "Capture",
        tournament_rules_settings_dc_and_mrkt: "Dip. center and market",
        tournament_rules_settings_allies: "Allies",
        tournament_rules_settings_limit_of_population: "Limit of population",
        tournament_rules_settings_game_speed: "Game speed",
        tournament_games_date: "Date",
        tournament_games_phase: "Round",
        tournament_games_games: "Games",
        tournament_games_score: "Score",
        tournament_games_youtube: "Youtube",
        tournament_join: "Join",
        tournament_leave: "Leave",
        tournament_technical_lose: "technical lose",
        /* tournament switz table */
        tournament_table_name: "Name",
        tournament_table_games: "Rounds",
        tournament_table_scoreswon: "Wins",
        tournament_table_scoresloss: "Losses",
        tournament_table_scores: "Scores",
        /* World Championship 2024 */
        wc2024_worldchampionship2024: "World Championship 2024",
        wc2024_description: "Welcome to the 2024 World Championship. The previous World Championship is considered the most spectacular tournament by WCC to this day. We are confident that this tournament will meet the expectations of both players and spectators. Unlike the previous tournament, this one will be held in WCC Mod, which will bring some features to the game.",
        wc2024_tournamentdescription: "Tournament description",
        wc2024_rulesurl: "https://wcc-cossacks.s3.eu-central-1.amazonaws.com/images/rules/15pt5k_gb.png",
        wc2024_format: "Format",
        wc2024_game: "Game",
        wc2024_mod: "Mod",
        wc2024_teams: "Teams",
        wc2024_peace_time: "Peace Time",
        wc2024_group_stage: "Group Stage",
        wc2024_playoff: "Play off",
        wc2024_3rdplace: "3rd place",
        wc2024_final: "final",
        wc2024_schedule: "Schedule",
        wc2024_registration: "Registration",
        wc2024_draw: "Draw",
        wc2024_features: "Features",
        wc2024_peaceofmystery: "A piece of mystery",
        wc2024_mysterydescription: "Players won't know colors and nations of their opponents",
        wc2024_pauseatstart: "Pause at start",
        wc2024_pausedescription: "The game will be paused on the 1st second, so all players will be in the same conditions. It is doesn't matter who was loaded first.",
        wc2024_palisadesfeature: "Expensive palisades (1 cell = 10 wood)",
        wc2024_palisadesfeaturedescription: "The palisade around your base will cost you about 1500-2000 of wood.",
        wc2024_wallsfeature: "Expensive walls (1 cell = 50 stone)",
        wc2024_wallsfeaturedescription: "The wall around your base will cost you about 4000-6000 of stone.",
        wc2024_modwcc: "Mod WCC",
        wc2024_modstep1: "Follow this button and subscribe to mod.",
        wc2024_modstep1button: "Mod WCC in Steam",
        wc2024_modwccstep2: "Check in Mod Manager, mod should be enabled.",
        wc2024_modwccstep3: "If mod manager is empty, text to Odin in discord WCC.",
        wc2024_poolnations: "Pool of nations",

        wc2024_howparticipate: "How to Participate",
        wc2024_howparticipatestep1: "Join to",
        wc2024_howparticipatestep1Button: "WCC Discord",
        wc2024_howtoparticipatestep2: "You will be directed to registration channel.",
        wc2024_howtoparticipatestep3: "Press Green button and obtain VISA to server.",
        wc2024_howtoparticipatestep4: "Leave information about you and your teammate in registration channel.",
        wc2024_jointelegram: "Join to Telegram to watch ALL Tournament Streams",
        wc2024_trophies: "Our trophies",
        wc2024_mins: "mins",
        wc2024_october: "October",
        wc2024_november: "November",
        wc2024_st: "st",
        wc2024_th: "th"
    },
    uk: {
        /* header */
        news: "Новини",
        rules: "Правила",
        ratings: "Рейтинги",
        tournaments: "Турніри",
        schedule: "Розклад",
        units: "Юніти",
        signin: "Вхід",
        manage_games: "Ігри",
        manage_players: "Гравці",
        logout: "Вийти",
        settings: "Налаштування",
        settings_nickname: "Нікнейм",
        settings_save: "Зберегти",
        /* ratings page */
        ratings_title: "РЕЙТИНГ",
        ratings_worldchampion: "Чемпіон Світу",
        ratings_name: "Імя",
        ratings_comment: "Коментар",
        ratings_score: "Рейтинг",
        /* tournaments page */
        tournaments_title: "Турніри",
        tournaments_card_participants: "учасників",
        tournaments_card_created_at: "Створено",
        tournaments_card_learn_more: "ДЕТАЛІ",
        /* single tournament */
        tournament_rules: "Правила",
        tournament_participants: "Учасники",
        tournament_games: "Ігри",
        tournament_bracket: "Сітка",
        tournament_rules_settings_setting: "Налаштування",
        tournament_rules_settings_value: "Значення",
        tournament_rules_settings_season: "Сезон",
        tournament_rules_settings_map_shape: "Тип мапи",
        tournament_rules_settings_terrain_type: "Вид рельєфу",
        tournament_rules_settings_starting_resources: "Початкові ресурси",
        tournament_rules_settings_minerals: "Ресурси",
        tournament_rules_settings_map_size: "Розмір карти",
        tournament_rules_settings_start_options: "Стартові опції",
        tournament_rules_settings_baloon_options: "Опції монгольф'єра",
        tournament_rules_settings_cannons: "Гармати",
        tournament_rules_settings_peace_time: "Час миру",
        tournament_rules_settings_18_century_options: "Опції 18 століття",
        tournament_rules_settings_capture: "Захоплення",
        tournament_rules_settings_dc_and_mrkt: "Дип.центр і ринок",
        tournament_rules_settings_allies: "Союзники",
        tournament_rules_settings_limit_of_population: "Ліміт населення",
        tournament_rules_settings_game_speed: "Швидкість гри",
        tournament_games_date: "Дата",
        tournament_games_phase: "Стадія",
        tournament_games_games: "Ігри",
        tournament_games_score: "Рахунок",
        tournament_games_youtube: "Відео",
        tournament_join: "Приєднатися",
        tournament_leave: "Відмовитися",
        tournament_technical_lose: "Технічна поразка",
        /* tournament switz table */
        tournament_table_name: "Ім'я",
        tournament_table_games: "Раунди",
        tournament_table_scoreswon: "Перемоги",
        tournament_table_scoresloss: "Поразки",
        tournament_table_scores: "Очки",
        /* World Championship 2024 */
        wc2024_worldchampionship2024: "Чемпіонат Світу 2024",
        wc2024_description: "Вітаємо Вас на Чемпіонаті Світу 2024. Попередній Чемпіонат Світу вважається найвидовищнішим турніром від WCC до цього часу. Ми впевнені, що цей турнір виправдає очікування як гравців, так і глядачів. На відміну від попереднього турніру, цей буде проведений у WCC Mod, що додасть грі кілька нових особливостей.",
        wc2024_tournamentdescription: "Опис турніру",
        wc2024_rulesurl: "https://wcc-cossacks.s3.eu-central-1.amazonaws.com/images/rules/15pt5k_uk.png",
        wc2024_format: "Формат",
        wc2024_game: "Гра",
        wc2024_mod: "Мод",
        wc2024_teams: "Команди",
        wc2024_peace_time: "Час Миру",
        wc2024_group_stage: "Груповий Етап",
        wc2024_playoff: "Плей офф",
        wc2024_3rdplace: "3тє місце",
        wc2024_final: "Фінал",
        wc2024_schedule: "Календар",
        wc2024_registration: "Реєстрація",
        wc2024_draw: "Жеребкування",
        wc2024_features: "Особливості",
        wc2024_peaceofmystery: "Частка таємниці",
        wc2024_mysterydescription: "Гравці не знатимуть кольори і нації своїх опонентів.",
        wc2024_pauseatstart: "Пауза на старті гри",
        wc2024_pausedescription: "Гра буде поставлена на паузу на 1-й секунді, щоб усі гравці були в однакових умовах. Не має значення, хто завантажився першим.",
        wc2024_palisadesfeature: "Дорогі паркани (1 клітинка = 10 дерева)",
        wc2024_palisadesfeaturedescription: "Паркан навколо вашої бази буде коштувати вам приблизно 1500-2000 дерева.",
        wc2024_wallsfeature: "Дорогі стіни (1 клітинка = 50 каміння)",
        wc2024_wallsfeaturedescription: "Стіна навколо вашої бази буде коштувати Вам приблизно 4000-6000 каміння.",
        wc2024_modwcc: "Мод WCC",
        wc2024_modstep1: "Перейдіть за цією кнопкою та підпишіться на Мод.",
        wc2024_modstep1button: "Мод WCC в Steam",
        wc2024_modwccstep2: "Перевірте Мод Менеджер, мод має бути увімкненим.",
        wc2024_modwccstep3: "Якщо Мод Менеджер порожній, напишіть Odin-у у дискорді WCC.",
        wc2024_poolnations: "Пул націй",

        wc2024_howparticipate: "Як прийняти участь",
        wc2024_howparticipatestep1: "Приєднатися до",
        wc2024_howparticipatestep1Button: "WCC Дискорд",
        wc2024_howtoparticipatestep2: "Ви будете перенаправлені у канал реєстрації.",
        wc2024_howtoparticipatestep3: "Натисніть на зелену кнопку та отримайте Візу доступу на сервер.",
        wc2024_howtoparticipatestep4: "Залиште інформацію про себе та Вашого напарника по команді у каналі реєстрації на турнір.",
        wc2024_jointelegram: "Приєднуйтеся до Телеграму, щоб мати можливість подивитись УСІ турнірні стріми.",
        wc2024_trophies: "Наші трофеї",
        wc2024_mins: "хв",
        wc2024_october: "Жовтень",
        wc2024_november: "Листопад",
        wc2024_st: "",
        wc2024_th: ""
    }
});

Strings.setLanguage(localStorage.getItem("Language") ?? 'gb');

export default Strings;