import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Grid, TextField } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import Strings from "../../components/LocalizedStrings";
import PageTitle from "../../components/PageTitle";
import Layout from "../../layout/layout";
import { GetTournamentsList } from "../../services/TournamentsService";
import SchedulePerTournament from "./schedulepertournament";

const Title = Strings.schedule;

interface TournamentProps {
    id: string,
    name: string
}

const Schedule = () => {
    const [tournamentList, setTournamentList] = useState<TournamentProps[]>();
    const [tournament, setTournament] = useState<string>("");

    useEffect(() => {
        GetTournamentsList().then(tournaments => {
            setTournamentList(tournaments.reverse());
        })
    },[]);

    function onTournamentChange(event: SyntheticEvent<Element, Event>, value: TournamentProps | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<TournamentProps> | undefined): void {
        setTournament(value?.id ?? "");
    }
    
    return (
        <Layout>
            <Grid container>
                <Grid item xs={12} textAlign={"center"} mb={5}>
                    <PageTitle text={Title} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={3} mb={3}>
                            <Autocomplete
                                options={tournamentList ?? []}
                                getOptionLabel={(option) => option.name}
                                disableCloseOnSelect
                                renderInput={(params) => (
                                    <TextField {...params} label="Tournaments" variant="standard" />
                                )}
                                onChange={onTournamentChange}
                            />
                        </Grid>
                        <Grid item xs={9} alignItems="flex-end">
                            
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <SchedulePerTournament tournamentId={tournament} />
                </Grid>
            </Grid>
        </Layout>
    );
};
export default Schedule;